@font-face {
  font-family: "SF Pro Display";
  src: url("./assets/fonts/SFProDisplay-Ultralight.ttf");
  font-style: "normal";
  font-weight: 100;
  font-display: "swap";
}

@font-face {
  font-family: "SF Pro Display";
  src: url("./assets/fonts/SFProDisplay-Light.ttf");
  font-style: "normal";
  font-weight: 200;
  font-display: "swap";
}

@font-face {
  font-family: "Lato Light";
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "SF Pro Display";
  src: url("./assets/fonts/SFProDisplay-Regular.ttf");
  font-style: "normal";
  font-weight: 400;
  font-display: "swap";
}

@font-face {
  font-family: "SF Pro Display";
  src: url("./assets/fonts/SFProDisplay-Medium.ttf");
  font-style: "normal";
  font-weight: 500;
  font-display: "swap";
}

@font-face {
  font-family: "SF Pro Display";
  src: url("./assets/fonts/SFProDisplay-Semibold.ttf");
  font-style: "normal";
  font-weight: 600;
  font-display: "swap";
}

@font-face {
  font-family: "SF Pro Display";
  src: url("./assets/fonts/SFProDisplay-Bold.ttf");
  font-style: "normal";
  font-weight: 700;
  font-display: "swap";
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "SF Pro Display", "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /*background: #f1f5f9;*/
  /*background: #D8CEBC;*/
  /*background: #3B2316;*/
  background: #e5e8ef;
  /*background: rgb(229,229,229);*/
  /*background: linear-gradient(359deg, rgba(229,229,229,1) 70%, rgba(117,116,118,1) 100%);*/
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.hero-image {
  /* Use "linear-gradient" to add a darken background effect to the image (photographer.jpg). This will make the text easier to read */
  background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url("./assets/images/reserve-at-hero-shot-1.JPEG");
  background-size: 75%;

  /* Set a specific height */
  height: 50%;

  /* Position and center the image to scale nicely on all screens */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}

.hero-image-slider {
  background-size: 75%;

  /* Set a specific height */
  height: 50%;

  /* Position and center the image to scale nicely on all screens */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}

/* Place text in the middle of the image */
.hero-text {
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  font-family: 'Montserrat' !important;
  /*font-family: 'Cabin' !important;*/
  font-size: 30px !important;
  font-weight: 700;
  /*text-transform: uppercase;*/
  text-shadow: 1px 1px 2px black;
  width:80%;
}

.hero-text-slider {

  position: absolute;
  bottom: 0;
  right: 0;
  transform: translate(-20%, -125%);
  color: white;
  font-family: 'Montserrat' !important;
  /*font-family: 'Cabin' !important;*/
  font-size: 20px !important;
  /*text-transform: uppercase;*/
  text-shadow: 1px 1px 2px black;
}

.hero-logo-slider {

  position: absolute !important;
  bottom: 0 !important;
  right: 0 !important;
  transform: translate(-90%, -90%) !important;
  width: 150px !important;
}

.hero-text-sub {
  text-align: center;
  position: absolute;
  top: 90%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  /*font-family: 'Dancing Script' !important;*/
  /*font-family: 'Whisper' !important;*/
  font-family: 'Montserrat' !important;
  font-size: 30px !important;
}

.subtitle1 {
  font-size: 16px !important;
  font-style: normal !important;
  font-weight: 700 !important;
  line-height: 20px !important;
  letter-spacing: 0.005em !important;
  text-align: left !important;
}

.title2 {
  font-size: 24px !important;
  font-style: normal !important;
  font-weight: 700 !important;
  line-height: 28px !important;
  letter-spacing: 0em !important;
  text-align: left !important;
}

.title3 {
  font-size: 20px !important;
  font-style: normal !important;
  font-weight: 700 !important;
  line-height: 24px !important;
  letter-spacing: 0.0075em !important;
  text-align: left !important;
}

.title3a {
  font-size: 20px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: 24px !important;
  letter-spacing: 0.0075em !important;
  text-align: left !important;
}

.title4 {
  font-size: 24px !important;
  font-style: normal !important;
  font-weight: 700 !important;
  line-height: 24px !important;
  letter-spacing: 0.0075em !important;
  text-align: left !important;
}

.title5 {
  font-size: 36px !important;
  font-style: normal !important;
  font-weight: 700 !important;
  line-height: 28px !important;
  letter-spacing: 0em !important;
}

.body1 {
  /*font-size: 16px !important;*/
  /*font-size: 18px !important;*/
  font-style: normal !important;
  line-height: 24px !important;
  letter-spacing: 0.0168em !important;
  text-align: left !important;
}

.body1 h1 {
  line-height: 40px !important;
}

.body2 {
  font-size: 14px !important;
  font-style: normal !important;
  line-height: 18px !important;
  letter-spacing: 0.0168em !important;
  text-align: left !important;
}
.body2a {
  font-size: 14px !important;
  font-style: normal !important;
  line-height: 18px !important;
  letter-spacing: 0.0168em !important;
}
.body3 {
  font-size: 10px !important;
  font-weight: 400 !important;
  line-height: 12px !important;
  letter-spacing: 0.15em !important;
  text-align: left !important;
}
.body4 {
  font-size: 20px !important;
  font-weight: 400 !important;
  line-height: 24px !important;
  letter-spacing: 0.0075em !important;
  text-align: left !important;
}
.body5 {
  font-size: 24px !important;
  font-weight: 400 !important;
  line-height: 24px !important;
  letter-spacing: 0.0168em !important;
  text-align: left !important;
}
.overline {
  font-size: 10px !important;
  font-style: normal !important;
  font-weight: 700 !important;
  line-height: 12px !important;
  letter-spacing: 0.15em !important;
  text-align: left !important;
}

.caption {
  font-size: 12px !important;
  font-style: normal !important;
  line-height: 14px !important;
  letter-spacing: 0.03em !important;
}

.button {
  padding: 12px 24px !important;
  border-radius: 8px !important;
}
.text-center{
  text-align: center !important;
}
.button-label {
  font-size: 14px !important;
  line-height: 16px !important;
  font-style: normal !important;
  letter-spacing: 0.05em !important;
}
.remove-link-design{
  text-decoration: none;
  color: #000;
}
.loader {
  position: fixed !important;
  top: 50%;
  left: 50%;
  z-index: 1;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
}
.loader svg {
  top: 50%;
  left: 50%;
  z-index: 1;
  transform: translate(-50%, -50%);
  position: fixed;
}
.feat-container {
  margin: 50px;
  height: 100%;
}
.unread td{
  background: #EEE !important;
}
.hover {
  cursor: pointer;
}

.w-100 {
  width: 100%;
}

.w-25 {
  width: 25%;
}

.green {
  color: green;
}

.grey-color {
  color: #64748b;
}
.tableFixHead {
  overflow: auto;
  height: 500px;
}
.tableFixHead thead th {
  position: sticky;
  top: 0;
  z-index: 1;
}

.flex-align-center {
  display: flex;
  align-items: center;
}
.flex-space-between {
  display: flex;
  justify-content: space-between;
}
.flex-justify-center {
  display: flex;
  justify-content: center;
}
.flex-justify-end {
  display: flex;
  justify-content: flex-end;
}
.d-block{
  display: block;
}
.error-cls{
  color: #d32f2f;
  font-family: SF Pro Display;
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 1.66;
  text-align: left;
  margin-top: 3px;
  margin-right: 14px;
  margin-bottom: 0;
  margin-left: 14px;
}
.elpsis-text {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.elpsis-text-1 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

.help-center-selected {
  background-color: "blue";
}

.border-bottom {
  border-bottom: 1px solid #e2e8f0;
  padding: 10px;
}
.border-top {
  border-top: 1px solid #e2e8f0;
  padding: 10px;
}
.zindex-0 {
  z-index: 0 !important;
}

.zindex-99 {
  z-index: 99 !important;
}

.w-50 {
  width: 50%;
}

.verify-code-styles {
  --ReactInputVerificationCode-itemWidth: 3rem;
  --ReactInputVerificationCode-itemHeight: 3rem;
}

.white {
  background-color: white;
}

.wrapword {
  white-space: -moz-pre-wrap !important; /* Mozilla, since 1999 */
  white-space: -webkit-pre-wrap; /* Chrome & Safari */
  white-space: -pre-wrap; /* Opera 4-6 */
  white-space: -o-pre-wrap; /* Opera 7 */
  white-space: pre-wrap; /* CSS3 */
  word-wrap: break-word; /* Internet Explorer 5.5+ */
  word-break: break-word;
  white-space: normal;
}

.restaurant-list-margin {
  margin: 5px 0 !important;
}
